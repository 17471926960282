import React, { FC, ReactElement } from 'react'
import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  Contact,
  GetUserProfilesQuery,
  LicenseInstance,
  useDeactivateLicenseMutation,
  useGetUserProfilesQuery,
} from '../../../../api/models'
import { toLocaleDateTime } from '../../../helpers/dateTime'
import { Add, DeleteOutline } from '@material-ui/icons'
import { GraphQLEndpoints } from '../../../helpers/apolloClient'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/EditOutlined'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { useRecoilValue } from 'recoil'
import { themeState } from '../../../helpers/recoil'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function ShowContact(props: { contact: Contact | undefined; headline: string }) {
  const { contact, headline } = props
  const { t } = useTranslation()
  return (
    <Box margin={1} style={{ marginTop: '30px' }}>
      <Typography variant="h6" gutterBottom component="div">
        {t(headline)}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '10%' }}>{t('contact.firstName')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.familyName')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.company')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.street')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.zip')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.city')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.country')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.note')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.number')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.email')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={contact?.id}>
            <TableCell>{contact?.first_name}</TableCell>
            <TableCell>{contact?.last_name}</TableCell>
            <TableCell>{contact?.company}</TableCell>
            <TableCell>{contact?.street}</TableCell>
            <TableCell>{contact?.post_code}</TableCell>
            <TableCell>{contact?.city}</TableCell>
            <TableCell>{contact?.country_code}</TableCell>
            <TableCell>{contact?.note}</TableCell>
            <TableCell>{contact?.customerid}</TableCell>
            <TableCell>{contact?.email}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

function Row(props: {
  licenseInstance: LicenseInstance
  deleteAction: (id: string) => void
  userProfiles?: GetUserProfilesQuery['getUserProfiles']
  i: string | number
}) {
  const { licenseInstance, deleteAction, userProfiles, i } = props
  const { system_identifiers } = licenseInstance
  const { RawMessage: SystemIdentifiers } = JSON.parse(system_identifiers || '')
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const userProfile = userProfiles?.find((userProfile) => userProfile?.userId === licenseInstance.user_id)
  const userProfileContact: Contact | undefined = userProfile
    ? {
        id: userProfile.userId,
        first_name: userProfile.FirstName,
        last_name: userProfile.LastName,
        company: userProfile.CompanyName,
        country_code: userProfile.Country,
        street: userProfile.Street,
        post_code: userProfile.Zip,
        city: userProfile.City,
        email: userProfile.Email,
      }
    : undefined

  const handleGoToLicense = () => {
    const path = generatePath(ROUTES.editLicense, { id: licenseInstance.license.id })
    history.push(path)
  }

  const background = licenseInstance.date_deleted ? { background: 'lightgrey' } : { background: 'none' }

  return (
    <>
      <TableRow className={classes.root} key={i} style={background}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{licenseInstance.license.license_key}</TableCell>
        <TableCell>{licenseInstance.contact.company || userProfileContact?.company || ''}</TableCell>
        <TableCell>{licenseInstance.contact.country_code || userProfileContact?.country_code || ''}</TableCell>
        <TableCell>{licenseInstance.contact.city || userProfileContact?.city || ''}</TableCell>
        <TableCell>{toLocaleDateTime(licenseInstance.date_created)}</TableCell>
        <TableCell style={{ minWidth: '200px' }}>
          {!licenseInstance.date_deleted && (
            <>
              <IconButton color="primary" component="span" onClick={() => deleteAction(licenseInstance.id)}>
                <DeleteOutline />
              </IconButton>
              <IconButton color="primary" component="span" onClick={() => handleGoToLicense()}>
                <EditIcon />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow key={`${i}-${licenseInstance.license.license_key}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {t('objects.license')}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('licenses.licenseKey')}</TableCell>
                    <TableCell>{t('note')}</TableCell>
                    <TableCell>{t('licensePools.orderNumber')}</TableCell>
                    <TableCell>{t('objects.licenseTypes')}</TableCell>
                    <TableCell>{t('instance.licenseMetadata')}</TableCell>
                    <TableCell>{t('licenses.maximumActivations')}</TableCell>
                    <TableCell>{t('objects.activations')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={licenseInstance.license.id}>
                    <TableCell component="th" scope="row">
                      {licenseInstance.license.license_key}
                    </TableCell>
                    <TableCell>{licenseInstance.license.note}</TableCell>
                    <TableCell>{licenseInstance.license.orderNumber}</TableCell>
                    <TableCell>
                      {licenseInstance.license.pools.map((pool) => (
                        <>
                          <Chip label={pool.license_type_key} style={{ marginRight: '15px' }} />
                        </>
                      ))}
                    </TableCell>
                    <TableCell>
                      <Table size="small">
                        {Object.keys(SystemIdentifiers)
                          .filter((key) => SystemIdentifiers[key] && SystemIdentifiers[key].length > 1)
                          .map((key) => (
                            <TableRow key={key}>
                              <TableCell>{t(`instance.${key}`)}:</TableCell>
                              <TableCell>{SystemIdentifiers[key]}</TableCell>
                            </TableRow>
                          ))}
                      </Table>
                    </TableCell>
                    <TableCell>{licenseInstance.license.activation_maximum}</TableCell>
                    <TableCell>{licenseInstance.license.nActivations + 1}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            {licenseInstance.contact?.id && (
              <ShowContact contact={licenseInstance.contact} headline={'objects.licenseServer'} />
            )}
            {userProfiles && userProfile && (
              <ShowContact contact={userProfileContact} headline={'objects.userProfilService'} />
            )}
            {licenseInstance.license.customer?.contact?.id && !userProfiles && (
              <ShowContact contact={licenseInstance.license.customer.contact} headline={'journal.CustomerID'} />
            )}
            {licenseInstance.license.merchant?.contact?.id && (
              <ShowContact contact={licenseInstance.license.merchant.contact} headline={'journal.MerchantID'} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

type Props = {
  licenseInstances: LicenseInstance[]
  refetch: () => void
  showHeadline?: boolean
  csvDownload?: boolean
  headline?: string
}

export const LicenseInstancesTable: FC<Props> = ({
  licenseInstances,
  refetch,
  showHeadline = true,
  csvDownload = false,
  headline = '',
}): ReactElement => {
  const { t } = useTranslation()
  const [deleteAction, { loading: deleteLoading }] = useDeactivateLicenseMutation()
  const currentTheme = useRecoilValue(themeState)

  let currentClientName = GraphQLEndpoints.UserProfileServiceCs
  console.log('currentClientName 1', { currentClientName })
  if (currentTheme === 'neutralLsLight') {
    currentClientName = GraphQLEndpoints.UserProfileServiceNeutral
  }

  console.log('currentClientName 2', { currentClientName })
  const { data: userProfiles } = useGetUserProfilesQuery({
    variables: {
      userIds:
        licenseInstances.map((licenseInstance) => {
          return licenseInstance.user_id
        }) || [],
    },
    context: { clientName: currentClientName },
  })

  const deactivateLicense = (id: string): void => {
    if (!deleteLoading) {
      deleteAction({ variables: { id } }).then(() => refetch())
    }
  }

  return (
    <>
      {showHeadline && (
        <Grid item xs={12}>
          <Typography color="secondary" style={{ paddingTop: 50 }} variant={'h5'}>
            {t('objects.activations')} {headline}
          </Typography>
        </Grid>
      )}
      {csvDownload && (
        <FixedActionBar
          labels={['grantLicenseLabel']}
          actions={[() => '']}
          icons={[Add]}
          buttonProps={[{ solid: true }]}
        />
      )}

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('licenses.licenseKey')}</TableCell>
                <TableCell>{t('contact.company')}</TableCell>
                <TableCell>{t('contact.country')}</TableCell>
                <TableCell>{t('contact.city')}</TableCell>
                <TableCell>{t('instance.dateCreated')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {licenseInstances.map((instance, index) => (
                <Row
                  i={index}
                  key={index}
                  licenseInstance={instance}
                  deleteAction={deactivateLicense}
                  userProfiles={userProfiles?.getUserProfiles}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}
